import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { differenceInMilliseconds } from 'date-fns';
import { jwtDecode } from 'jwt-decode';
import { Typography } from 'components/Typography';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { IconButton } from 'components/IconButton';
import { TransitionFade } from 'components/Transition';
import * as RouteConstants from 'constants/routes';
import Close from 'icons/Close';
import { useAuth } from 'services';
import { JwtPayload } from "jsonwebtoken";
import { passportService } from 'libs/axios';
import { removeStorage } from 'pages/Logout';

const DEBOUNCE_TIME = 1000 * 30;
const DEFAULT_EXP_TIME = 1000 * 60 * 20;
const OFFSET_EXP_TIME = 1000 * 60;
const PROMPT_TIMEOUT = 1000 * 59;

function getExpTimeFromUserInfo(jwtData: JwtPayload | null) {
  let expTime: number = DEFAULT_EXP_TIME;

  if (jwtData && jwtData.iat && jwtData.exp) {
    expTime = differenceInMilliseconds(jwtData.exp, jwtData.iat);
  }

  return expTime - PROMPT_TIMEOUT - OFFSET_EXP_TIME;
}

function SessionTimeout() {
  const navigate = useNavigate();

  const { jwtData } = useAuth();

  const [timeout, setTimeoutValue] = useState(getExpTimeFromUserInfo(jwtData)); // Set timeout values
  const [open, setOpen] = useState(false); // Modal open state
  const [remaining, setRemaining] = useState(0); // Time before idle

  const promptTimeout = PROMPT_TIMEOUT;

  const extendSession = async () => {
    try {
      const response = await passportService({
        method: 'post',
        url: `/misc/refresh`,
        data: {
          minutes: 20,
        },
      });

      const jwtToken = response.headers.authorization;
      const [, tokenString] = jwtToken.split(' ');
      const _jwtData = tokenString ? jwtDecode(tokenString) : null;

      setTimeoutValue(getExpTimeFromUserInfo(_jwtData));
    } catch (e) {
      removeStorage()
      navigate(RouteConstants.SIGN_IN)
    }
  };

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    // In this case 20 minutes. Here you can open your prompt.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `reset()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setOpen(true);
    setRemaining(promptTimeout / 1000);
  };

  const onIdle = async () => {
    // onIdle will be called after the promptTimeout is reached.
    // In this case 59 seconds. Here you can close your prompt and
    // perform what ever idle action you want such as log out your user.
    // Events will be rebound as long as `stopOnMount` is not set.

    navigate(RouteConstants.LOGOUT);
  };

  const onActive = async () => {
    // onActive will only be called if `reset()` is called while `isPrompted()`
    // is true. Here you will also want to close your modal and perform
    // any active actions.
    setOpen(false);
    setRemaining(0);

    extendSession();
  };

  const onAction = async () => {
    // onAction will be called on user activity (after debounce or throttle condition is met if specified).
    extendSession();
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    timeout,
    promptTimeout,
    debounce: DEBOUNCE_TIME,
    onPrompt,
    onIdle,
    onActive,
    onAction,
  });

  const handleStillHere = () => {
    setOpen(false);
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <Modal show={open} TransitionComponent={TransitionFade}>
      <div className="relative flex flex-col justify-between rounded bg-white p-5">
        <IconButton title="Close" component={Button} variant="text" color="text" className="self-end" onClick={handleStillHere}>
          <Close ariaLabel='close modal' />
        </IconButton>
        <div className="flex flex-col items-center gap-4">
          <Typography variant="body4" component="h3" className="text-48-60">
            0 : {remaining}
          </Typography>
          <Typography variant="heading" component="h4" className="pt-4 text-24-30">
            Are you still there?
          </Typography>
          <Typography variant="body1" component="p" className="text-center">
            For your security we will log you out automatically due to inactivity.
          </Typography>
          <Button underline variant="contained" color="primary" className="flex w-full" onClick={handleStillHere}>
            Stay logged in
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default SessionTimeout;
