import IconProps from "./IconProps";

function AccountBalance(props: IconProps) {
  const { width = 24, height = 24, fill = '#96918B', ariaLabel} = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={ariaLabel}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1436 7.36334L12 0L0.856395 7.36334C-0.0826726 7.98312 -0.0524137 8.63421 0.0487969 8.96705C0.150008 9.29886 0.487029 9.85499 1.61287 9.85499H2.60932V19.7674H0.522504V23.4193H1.56591V20.8108H2.60932H6.78296H9.91318H14.0868H17.217H21.3907H22.4341V23.4193H23.4775V19.7674H21.3907V9.85499H22.3871C23.513 9.85499 23.85 9.29886 23.9512 8.96705C24.0524 8.63421 24.0827 7.98312 23.1436 7.36334ZM3.65273 19.7674V9.85499H5.73955V19.7674H3.65273ZM6.78296 19.7674V9.85499H9.91318V19.7674H6.78296ZM10.9566 19.7674V9.85499H13.0434V19.7674H10.9566ZM14.0868 19.7674V9.85499H17.217V19.7674H14.0868ZM20.3473 19.7674H18.2605V9.85499H20.3473V19.7674ZM22.3871 8.81159H21.3907H17.217H14.0868H9.91318H6.78296H2.60932H1.61287C1.23098 8.81159 1.06612 8.7229 1.04734 8.66342C1.0296 8.60499 1.11725 8.44222 1.43236 8.23354L12 1.25105L22.5687 8.23354C22.8828 8.44118 22.9704 8.60395 22.9527 8.66342C22.9339 8.7229 22.769 8.81159 22.3871 8.81159Z"
        fill={fill}
      />
    </svg>
  );
}

export default AccountBalance;
