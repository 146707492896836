import IconProps from "./IconProps";

function MasterCardLogo(props: IconProps) {
  const { width = 41, height = 25, className, ariaLabel } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 41 25" fill="none" className={className} xmlns="http://www.w3.org/2000/svg" aria-label={ariaLabel}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.8445 19.3496V20.3496H39.7571V19.5936L39.6231 20.2459H39.5324L39.3988 19.595V20.3496H39.311V19.3496H39.4353L39.5779 20.0372L39.7206 19.3496H39.8445ZM39.0589 19.5192V20.3493H38.9725V19.5192H38.8135V19.35H39.218V19.5192H39.0589Z"
        fill="#F89E1B"
      />
      <path d="M14.8423 21.8736H25.6679V3.02539H14.8423V21.8736Z" fill="#F16522" />
      <path
        d="M15.5395 12.375C15.5395 8.54694 17.3837 5.13783 20.2551 2.94061C18.1553 1.33374 15.5051 0.375 12.6252 0.375C5.80667 0.375 0.279297 5.74755 0.279297 12.375C0.279297 19.0024 5.80667 24.375 12.6252 24.375C15.5051 24.375 18.1553 23.4163 20.2551 21.8094C17.3837 19.6122 15.5395 16.2031 15.5395 12.375Z"
        fill="#E52523"
      />
      <path
        d="M40.2312 12.375C40.2312 19.0024 34.7038 24.375 27.8853 24.375C25.0051 24.375 22.3552 23.4163 20.2554 21.8094C23.1268 19.6122 24.971 16.2031 24.971 12.375C24.971 8.54694 23.1268 5.13783 20.2554 2.94061C22.3552 1.33374 25.0051 0.375 27.8853 0.375C34.7038 0.375 40.2312 5.74755 40.2312 12.375Z"
        fill="#F89E1B"
      />
    </svg>
  );
}

export default MasterCardLogo;
