import IconProps from "./IconProps";

function Menu(props: IconProps) {
  const { width = 24, height = 19, ariaLabel } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={ariaLabel}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H24V1.33333H0V0ZM0 8.66667H24V10H0V8.66667ZM24 17.3333H0V18.6667H24V17.3333Z" fill="#141413" />
    </svg>
  );
}

export default Menu;
