import IconProps from "./IconProps"

function KeyIcon(props: IconProps) {
    const { width = 24, height = 24, ariaLabel } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.75 3C12.8505 3 10.5 5.35051 10.5 8.25C10.5 8.55776 10.5264 8.85876 10.5769 9.15101C10.7043 9.88817 10.5573 10.7534 9.93833 11.3723L3.43934 17.8713C3.15804 18.1526 3 18.5342 3 18.932V21H5.25V19.5C5.25 19.0858 5.58579 18.75 6 18.75H7.5V17.25C7.5 16.8358 7.83579 16.5 8.25 16.5H10.1893L12.6277 14.0617C13.2466 13.4427 14.1118 13.2957 14.849 13.4231C15.1412 13.4736 15.4422 13.5 15.75 13.5C18.6495 13.5 21 11.1495 21 8.25C21 5.35051 18.6495 3 15.75 3ZM9 8.25C9 4.52208 12.0221 1.5 15.75 1.5C19.4779 1.5 22.5 4.52208 22.5 8.25C22.5 11.9779 19.4779 15 15.75 15C15.3563 15 14.9698 14.9662 14.5936 14.9012C14.2047 14.834 13.8774 14.9332 13.6883 15.1223L11.0303 17.7803C10.8897 17.921 10.6989 18 10.5 18H9V19.5C9 19.9142 8.66421 20.25 8.25 20.25H6.75V21.75C6.75 22.1642 6.41421 22.5 6 22.5H2.25C1.83579 22.5 1.5 22.1642 1.5 21.75V18.932C1.5 18.1363 1.81607 17.3733 2.37868 16.8107L8.87767 10.3117C9.06678 10.1226 9.16599 9.79527 9.0988 9.40639C9.03379 9.03016 9 8.64372 9 8.25ZM15 5.25C15 4.83579 15.3358 4.5 15.75 4.5C17.8211 4.5 19.5 6.17893 19.5 8.25C19.5 8.66421 19.1642 9 18.75 9C18.3358 9 18 8.66421 18 8.25C18 7.00736 16.9926 6 15.75 6C15.3358 6 15 5.66421 15 5.25Z" fill="#25836D" />
        </svg>

    )
}

export default KeyIcon