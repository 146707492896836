import IconProps from "./IconProps";

function LearnMoreIllustration(props: IconProps) {
  const { width = 216, height = 122 , ariaLabel} = props;

  return (
    <svg width={width} height={height} viewBox="0 0 216 122" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={ariaLabel}>
      <mask id="mask0_6289_54613" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="216" height="122">
        <rect x="0.549194" width="215.166" height="122" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_6289_54613)">
        <path d="M202.409 -150.379H1.37231V118.158H202.409V-150.379Z" fill="white" />
        <path d="M215.712 -150.379H1.40112V118.238H215.712V-150.379Z" fill="white" />
        <path
          d="M121.414 112.81L85.0458 94.6776C82.4273 93.3721 79.4601 92.8316 76.5184 93.1244C73.5768 93.4173 70.7923 94.5303 68.5161 96.3231L6.0083 145.558L15.9875 154.484L77.8402 114.449L123.707 149.623C128.19 152.28 142.58 155.19 150.219 142.723L121.414 112.81Z"
          fill="#353432"
        />
        <path
          d="M131.163 103.261L99.26 78.6974C96.963 76.9287 94.1657 75.8452 91.2208 75.5834C88.2759 75.3217 85.3152 75.8934 82.7122 77.2265L19.6648 104.465L24.8584 116.773L88.0593 96.1777L124.642 115.157C128.487 118.599 143.139 148.763 153.211 137.965L131.163 103.261Z"
          fill="#353432"
        />
        <path
          d="M3.31482 91.4614C3.31482 91.4614 9.45231 90.5684 13.2123 95.6139L19.6576 104.453L24.857 116.773L15.4984 120.331L3.31482 91.4614Z"
          fill="#301C00"
        />
        <path
          d="M23.8893 114.478C22.4947 114.99 20.9436 114.96 19.5723 114.393C18.201 113.827 17.1201 112.771 16.564 111.453C16.008 110.135 16.0216 108.663 16.6019 107.355C17.1822 106.046 18.2824 105.008 19.6639 104.465"
          fill="#B28C16"
        />
        <path
          d="M131.419 14.2051L95.0185 23.2805C94.2786 23.465 93.8364 24.1838 94.0307 24.8861L106.952 71.5798C107.146 72.282 107.904 72.7018 108.644 72.5173L145.044 63.4419C145.784 63.2574 146.226 62.5386 146.031 61.8363L133.11 15.1427C132.916 14.4404 132.158 14.0206 131.419 14.2051Z"
          fill="#555350"
        />
        <path d="M131.357 16.0003L96.4197 24.7109L108.957 70.0161L143.894 61.3054L131.357 16.0003Z" fill="white" />
        <path
          d="M112.886 45.3933C113.447 47.4608 112.137 49.5784 109.945 50.1145C107.753 50.6507 105.529 49.3976 104.967 47.3301C104.406 45.2626 105.716 43.145 107.908 42.6089C110.1 42.0727 112.325 43.3258 112.886 45.3933Z"
          stroke="#555250"
          strokeWidth="0.25"
        />
        <path
          d="M124.108 42.6485C124.67 44.7161 123.36 46.8337 121.168 47.3698C118.976 47.9059 116.751 46.6528 116.19 44.5853C115.628 42.5178 116.938 40.4002 119.13 39.8641C121.322 39.328 123.547 40.581 124.108 42.6485Z"
          stroke="#555250"
          strokeWidth="0.25"
        />
        <path
          d="M135.33 39.9038C135.892 41.9713 134.582 44.0889 132.39 44.625C130.198 45.1611 127.973 43.9081 127.412 41.8406C126.85 39.7731 128.16 37.6555 130.352 37.1193C132.544 36.5832 134.769 37.8363 135.33 39.9038Z"
          stroke="#555250"
          strokeWidth="0.25"
        />
        <ellipse rx="0.525134" ry="0.501708" transform="matrix(0.971368 -0.237581 0.261984 0.965072 114.669 45.4735)" fill="#555250" />
        <ellipse rx="0.525134" ry="0.501708" transform="matrix(0.971368 -0.237581 0.261984 0.965072 125.891 42.7287)" fill="#555250" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M122.087 28.0405L122.161 28.3046L122.138 28.3105L122.082 28.1108L122.095 28.2922L122.07 28.2983L121.987 28.1354L122.042 28.3347L122.019 28.3406L121.945 28.0765L121.978 28.0681L122.067 28.2401L122.054 28.0489L122.087 28.0405ZM121.89 28.1384L121.952 28.3576L121.928 28.3634L121.867 28.1442L121.825 28.1549L121.812 28.1102L121.92 28.0829L121.933 28.1276L121.89 28.1384Z"
          fill="#F89E1B"
        />
        <path d="M115.596 30.3932L118.488 29.6633L117.093 24.6855L114.202 25.4154L115.596 30.3932Z" fill="#F16522" />
        <path
          d="M115.08 27.8375C114.796 26.8265 115.037 25.8018 115.641 25.0279C114.961 24.7452 114.182 24.6706 113.413 24.8648C111.592 25.3246 110.513 27.1162 111.003 28.8665C111.494 30.6168 113.368 31.663 115.189 31.2032C115.958 31.009 116.595 30.5771 117.037 30.0112C116.108 29.6245 115.363 28.8485 115.08 27.8375Z"
          fill="#E52523"
        />
        <path
          d="M121.675 26.1727C122.165 27.923 121.086 29.7145 119.265 30.1743C118.496 30.3685 117.717 30.294 117.037 30.0112C117.642 29.2373 117.882 28.2126 117.599 27.2016C117.315 26.1906 116.571 25.4146 115.641 25.0279C116.083 24.462 116.72 24.0301 117.489 23.8359C119.31 23.3762 121.184 24.4224 121.675 26.1727Z"
          fill="#F89E1B"
        />
        <path
          d="M177.549 17.7814C167.612 16.3085 158.027 24.4063 156.475 33.8391C154.923 43.2719 161.559 53.0998 171.497 54.5727C181.435 56.0457 190.911 48.6056 192.463 39.173C194.32 27.8836 187.487 19.2543 177.549 17.7814Z"
          fill="#714C19"
        />
        <path d="M191.645 35.9277C194.264 36.2741 192.463 39.1708 190.323 44.9288L191.645 35.9277Z" fill="#AB6100" />
        <path
          d="M173.511 47.4496L181.631 48.6531L179.505 61.5744C179.505 61.5744 183.211 73.4122 171.969 71.7461C160.624 70.0645 163.37 59.1655 163.37 59.1655L165.391 46.2461L173.511 47.4496Z"
          fill="#714C19"
        />
        <path
          d="M138.507 123.668L170.765 110.16L175.55 99.1777C178.565 100.653 186.876 106.047 183.133 115.298C182.631 116.54 181.802 118.627 180.978 120.712C180.2 122.68 178.863 124.405 177.115 125.695C175.367 126.985 173.277 127.79 171.076 128.021L143.611 130.897L138.507 123.668Z"
          fill="#714C19"
        />
        <path
          d="M183.135 115.299L191.941 90.6241C193.825 84.576 191.361 77.5195 186.862 72.8783C186.6 72.6085 179.827 62.0745 179.827 62.0745L164.543 56.6074L146.131 58.3501C142.993 58.6472 140.08 60.033 137.952 62.2422L121.522 79.297L138.3 94.1141L148.968 85.9296L136.987 80.8738L117.953 127.732L161.147 144.264C161.147 144.264 170.46 121.657 173.898 112.475C176.854 113.53 179.841 114.524 183.135 115.299Z"
          fill="#FF671B"
        />
        <path
          d="M160.656 24.8651C171.17 26.6456 173.365 36.8385 173.365 36.8385C173.365 36.8385 177.512 36.0626 176.963 39.4024C176.393 42.8659 180.456 49.6704 183.097 50.0617C188.689 50.8906 193.844 41.9794 194.384 38.6953L195.069 34.0469C195.18 32.44 195.053 30.8262 194.69 29.2531C194.132 26.7676 193.021 24.4238 191.433 22.3783C189.844 20.3328 187.813 18.6327 185.477 17.3917C185.342 17.3215 185.208 17.2513 185.06 17.1789L185.562 14.1284L174.367 12.4697C169.118 11.6918 161.866 12.041 161.047 17.0221L156.615 16.3654C156.297 18.296 159.061 22.5372 160.656 24.8651Z"
          fill="#301C00"
        />
        <path
          d="M172.95 42.6559C175.176 42.6559 176.981 40.943 176.981 38.8299C176.981 36.7169 175.176 35.0039 172.95 35.0039C170.724 35.0039 168.919 36.7169 168.919 38.8299C168.919 40.943 170.724 42.6559 172.95 42.6559Z"
          fill="#714C19"
        />
        <path
          d="M155.462 42.2734C155.672 42.7662 156.012 43.199 156.45 43.5295C156.889 43.8599 157.41 44.0767 157.963 44.1587C158.516 44.2407 159.082 44.185 159.606 43.9971C160.129 43.8092 160.593 43.4956 160.95 43.0868L155.462 42.2734Z"
          fill="white"
        />
        <path
          d="M162.337 34.6601C162.291 34.9431 162.157 35.2066 161.952 35.4174C161.748 35.6281 161.482 35.7766 161.189 35.8441C160.896 35.9115 160.588 35.895 160.305 35.7964C160.021 35.6979 159.775 35.5218 159.597 35.2904C159.419 35.0591 159.318 34.7828 159.305 34.4966C159.293 34.2104 159.37 33.9272 159.527 33.6826C159.684 33.4381 159.914 33.2432 160.188 33.1227C160.461 33.0022 160.767 32.9614 161.065 33.0056C161.263 33.0349 161.453 33.101 161.624 33.2C161.795 33.299 161.944 33.4291 162.062 33.5827C162.18 33.7363 162.265 33.9106 162.312 34.0954C162.36 34.2803 162.368 34.4721 162.337 34.6601Z"
          fill="#301C00"
        />
        <path
          d="M99.1039 45.692C99.3643 45.7267 99.6105 45.8257 99.8179 45.9792C100.025 46.1328 100.186 46.3353 100.285 46.5666L103.363 58.1013L103.529 51.0109C104.124 51.16 104.657 51.4769 105.058 51.9197C105.459 52.3626 105.709 52.9105 105.775 53.491C106.192 59.5099 107.693 59.7963 107.394 63.8008C107.252 65.7091 105.127 68.7906 105.127 68.7906L97.7531 69.9623C98.6232 55.318 97.6328 49.6991 97.8945 47.6627C98.0109 46.757 97.8568 45.544 99.1039 45.692Z"
          fill="#714C19"
        />
        <path
          d="M105.755 99.5591L97.7531 69.9608L105.127 68.7891L114.783 90.4299L110.905 89.9803L121.518 79.2939L133.928 90.253L115.675 103.997C111.707 106.984 105.853 104.365 105.755 99.5591Z"
          fill="#714C19"
        />
        <path
          d="M173.325 101.801H109.518C105.715 101.801 101.95 102.391 98.4366 103.538C94.9234 104.685 91.7313 106.367 89.0425 108.486C86.3536 110.605 84.2207 113.122 82.7655 115.891C81.3103 118.66 80.5614 121.628 80.5614 124.625V155.933H202.281V124.626C202.282 121.628 201.533 118.66 200.077 115.891C198.622 113.122 196.489 110.606 193.801 108.486C191.112 106.367 187.92 104.685 184.406 103.538C180.893 102.391 177.128 101.801 173.325 101.801Z"
          fill="#AA6100"
        />
        <path
          d="M173.325 101.801H109.518C105.715 101.801 101.95 102.391 98.4366 103.538C94.9234 104.685 91.7313 106.367 89.0425 108.486C86.3536 110.605 84.2207 113.122 82.7655 115.891C81.3103 118.66 80.5614 121.628 80.5614 124.625V155.933H202.281V124.626C202.282 121.628 201.533 118.66 200.077 115.891C198.622 113.122 196.489 110.606 193.801 108.486C191.112 106.367 187.92 104.685 184.406 103.538C180.893 102.391 177.128 101.801 173.325 101.801V101.801Z"
          stroke="#242424"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
}

export default LearnMoreIllustration;
