import IconProps from './IconProps';

function ArrowLeft(props: IconProps) {
  const { width = 9, height = 17, ariaLabel } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={ariaLabel}>
      <path d="M7.42857 1L1 8.5L7.42857 16" stroke="#555250" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ArrowLeft;
