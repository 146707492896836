import { formatDistance, format, differenceInHours, getUnixTime } from 'date-fns';

const todayDate = new Date();

const getFormatDistance = (timestamp: number | Date): string => {
  return formatDistance(timestamp, todayDate, {
    addSuffix: true,
    includeSeconds: true,
  });
};

const getFormat = (timestamp: number | Date, _format = 'MMM d, yyyy'): string => {
  return format(timestamp, _format);
};

const getCurrentYear = () => {
  return new Date().getFullYear();
};

const getMonthAndDay = (timestamp: number) => format(timestamp, 'MMMM do');

const getRelativeTimeAgo = (timestamp: number | Date | string) => {
  const date = new Date(timestamp);
  const difference = differenceInHours(todayDate, date);

  if (difference < 1) {
    return 'A few minutes ago';
  } else if (difference < 24) {
    return 'Today';
  } else if (difference < 48) {
    return 'Yesterday';
  } else {
    return format(date, 'MMMM do');
  }
};

const getLocalDateTimeFromEpoch = (epochTimeStamp: number, formatStr = 'MM/dd/yyyy hh:mmaaa'): string => {
  if(!epochTimeStamp) return ''
  const unixTime = getUnixTime(new Date(epochTimeStamp * 1000));
  return format(new Date(unixTime * 1000), formatStr);
}

export { getFormatDistance, getFormat, getCurrentYear, getMonthAndDay, getRelativeTimeAgo, getLocalDateTimeFromEpoch };
