import IconProps from "./IconProps";

function NavApp(props: IconProps) {
  const { width = 24, height = 24, fill = '#96918B' , ariaLabel} = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={ariaLabel}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.89543 0.89543 0 2 0H16C17.1046 0 18 0.89543 18 2V6H22C23.1046 6 24 6.89543 24 8V22C24 23.1046 23.1046 24 22 24H8C6.89543 24 6 23.1046 6 22V18H2C0.89543 18 0 17.1046 0 16V2ZM8 18V22H22V8H18V16C18 17.1046 17.1046 18 16 18H8ZM16 8V16H8V8H16ZM16 6H8C6.89543 6 6 6.89543 6 8V16H2V2H16V6Z"
        fill={fill}
      />
    </svg>
  );
}

export default NavApp;
