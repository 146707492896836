import IconProps from './IconProps';

function AccountCircleOutlined(props: IconProps) {
  const { width = 24, height = 24, fill = '#CF4500', ariaLabel } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={props.ariaLabel}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4853 3.51471C18.2188 1.24823 15.2053 0 12 0C8.79456 0 5.78119 1.24823 3.51471 3.51471C1.24823 5.78119 0 8.79456 0 12C0 15.2053 1.24823 18.2188 3.51471 20.4853C5.78119 22.7518 8.79456 24 12 24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79456 22.7518 5.78119 20.4853 3.51471ZM6.01593 20.7369C6.51782 17.8471 9.02197 15.7156 12 15.7156C14.9782 15.7156 17.4822 17.8471 17.9841 20.7369C16.2803 21.9075 14.2189 22.5938 12 22.5938C9.78113 22.5938 7.71973 21.9075 6.01593 20.7369ZM8.18445 10.4938C8.18445 8.38971 9.89612 6.67822 12 6.67822C14.1039 6.67822 15.8156 8.38989 15.8156 10.4938C15.8156 12.5977 14.1039 14.3093 12 14.3093C9.89612 14.3093 8.18445 12.5977 8.18445 10.4938ZM19.2021 19.7615C18.8236 18.4162 18.072 17.1956 17.0184 16.2422C16.372 15.6572 15.6363 15.1956 14.8453 14.8702C16.275 13.9376 17.222 12.3243 17.222 10.4938C17.222 7.61444 14.8793 5.27197 12 5.27197C9.12067 5.27197 6.7782 7.61444 6.7782 10.4938C6.7782 12.3243 7.72522 13.9376 9.15472 14.8702C8.36389 15.1956 7.62799 15.657 6.98163 16.242C5.92822 17.1954 5.17639 18.416 4.79791 19.7613C2.71326 17.8253 1.40625 15.0626 1.40625 12C1.40625 6.15857 6.15857 1.40625 12 1.40625C17.8414 1.40625 22.5938 6.15857 22.5938 12C22.5938 15.0628 21.2867 17.8255 19.2021 19.7615Z"
        fill={fill}
      />
    </svg>
  );
}

export default AccountCircleOutlined;
