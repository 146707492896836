import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosInterceptor } from 'libs/axios';
import { AnalyticsProvider } from 'libs/analytics';
import { I18nextProvider } from 'libs/i18next';
import AppRoutes from './Routes';
import SetMarket from 'services/MarketSetter';
import { useFeatureFlagState } from 'hooks/useFeatureFlag';
import * as SentryReact from '@sentry/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appStorage } from 'utils/storage';
import { useEffect } from 'react';
import { ErrorLogger } from 'utils/errorLogger';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryDelay: 1000,
      retry: 2,
    },
  },
});

function App() {
  const allFeatures = useFeatureFlagState();
  appStorage.removeItem('connectPlusSessionId');
  const state = useFeatureFlagState();
  const  { features, configIsLoaded, configLoadingError } = state
  const ENV = features?.ENV || null

  useEffect(() => {
    if (configIsLoaded || configLoadingError) {
      ErrorLogger.init(allFeatures.features?.ENV);
    }
  }, [configIsLoaded, configLoadingError])


  return (
    <BrowserRouter>
      {/* This is for Kube  */}
      <Routes>
        <Route path="/live" element={<h1>Live</h1>} />
        <Route path="*" element={<></>} />
      </Routes>
      <SetMarket>
        <AnalyticsProvider>
          <I18nextProvider>
            <QueryClientProvider client={queryClient}>
              <AxiosInterceptor>
                <AppRoutes />
                <ReactQueryDevtools initialIsOpen={false} />
                <ToastContainer />
              </AxiosInterceptor>
            </QueryClientProvider>
          </I18nextProvider>
        </AnalyticsProvider>
      </SetMarket>
    </BrowserRouter>
  );
}

export default SentryReact.withProfiler(App);
