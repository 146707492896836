import { ReactNode } from 'react';
import { useMatch } from 'react-router-dom';
import clsx from 'clsx';

import { Link, Props as LinkProps } from 'components/Link';
import { Typography } from 'components/Typography';

interface NavLinkProps extends LinkProps {
  label: string;
  icon: ReactNode;
  className?: string;
}

function NavLink({ to, label, icon, className }: NavLinkProps) {
  const match = useMatch(to + '/*');

  return (
    <Link to={to} variant="text" color="text" className={clsx('flex items-center gap-4 p-5', [match ? '!text-[#CF4500]' : '!text-[#96918B]'], className)}>
      {icon}
      <Typography variant="body" component="p" className="text-16-24 text-inherit">
        {label}
      </Typography>
    </Link>
  );
}

export default NavLink;
